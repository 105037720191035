import React, { useState } from 'react';
import '../styles/CourseCard.css'; // CSS dosyanızı burada import edin

const CourseCard = ({ title, date, total, students, time, info }) => {
    const [isOpen, setIsOpen] = useState(false); // Durum oluşturma

    const toggleInfo = () => {
        setIsOpen(!isOpen); // Durumu değiştirir
    };

    return (
        <div className= "cebir-course-card">
            <div className="cebir-course-content">
                <h1 className="cebir-course-title">{title}</h1>
                <p className="cebir-course-date">Tarih: {date}</p>
                <p className="cebir-course-hours">Ders Saatleri: {time}</p>
                <p className="cebir-student-size">Sınıf Kontenjanı: {total}</p>
                <p className="cebir-student-size">Mevcut Kontenjan: {students}</p>
                {isOpen && <p className="cebir-course-info">{info}</p>} {/* Duruma bağlı olarak paragrafı gösterir */}
                <button className="cebir-course-button" onClick={toggleInfo}>
                    {isOpen ? 'Kapat' : 'İncele'}
                </button> {/* Buton tıklama işlemi */}
            </div>
        </div>
    );
};

export default CourseCard;
