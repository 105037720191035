import React, { useEffect, useState } from 'react';
import CourseCard from './CourseCard/CourseCard';
import './styles/Course.css';


const API_URL = process.env.REACT_APP_API_URL;

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');

    

    const months = [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];

    // Function to format dates to "DD-MM-YYYY"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

  
    
    

    // Fetch all courses from the database
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_CEBIR}/api/admin/course/cebir/all`); // Endpoint to get all courses
                if (response.ok) {
                    const data = await response.json();
                    setCourses(data);
                    setFilteredCourses(data); // Set filtered courses initially to all courses
                } else {
                    console.error('Kurslar alınırken hata oluştu');
                }
            } catch (error) {
                console.error('Hata:', error);
            }
        };
        fetchCourses(); // Start fetch operation with useEffect
    }, []);

    

    // Filter courses based on selected month, year, and level
    useEffect(() => {
        const filtered = courses.filter(course => {
            const startDate = new Date(course.startDate);
            const endDate = new Date(course.endDate);
            const courseTitle = course.title;
          
            // Check if selected month and year match either startDate or endDate
            const monthMatches = selectedMonth
                ? (startDate.getMonth() + 1 === Number(selectedMonth) || endDate.getMonth() + 1 === Number(selectedMonth))
                : true;
            
            const yearMatches = selectedYear
                ? (startDate.getFullYear() === Number(selectedYear) || endDate.getFullYear() === Number(selectedYear))
                : true;
    
            // Case-insensitive level match using RegExp
            const levelMatches = selectedLevel
                ? courseTitle.match(new RegExp(`(?:^|\\s)(?:${selectedLevel})(?:[-–]\\d+)?\\.?\\s?Sınıflar?`, 'i')) // Case-insensitive search
                : true;
    
            // Return true only if all matches are true
            return monthMatches && yearMatches && levelMatches;
        });
    
        setFilteredCourses(filtered);
    }, [selectedMonth, selectedYear, selectedLevel, courses]);
    
    return (
        <div>
            <div className="filter-container">
                <label>
                    Ay:
                    <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                        <option value="">Tüm Aylar</option>
                        {months.map((month, index) => (
                            <option key={index} value={index + 1}>{month}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Yıl:
                    <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                        <option value="">Tüm Yıllar</option>
                        {[2020,2021,2022, 2023, 2024, 2025].map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Seviye:
                    <select value={selectedLevel} onChange={(e) => setSelectedLevel(e.target.value)}>
                        <option value="">Tüm Seviyeler</option>
                        <option value="1">1.Sınıflar</option>
                        <option value="2">2.Sınıflar</option>
                        <option value="3">3.Sınıflar</option>
                        <option value="4">4.Sınıflar</option>
                        <option value="5">5.Sınıflar</option>
                        <option value="6">6.Sınıflar</option>
                        <option value="7">7.Sınıflar</option>
                        <option value="8">8.Sınıflar</option>
                        <option value="9">9.Sınıflar</option>
                        <option value="10">10.Sınıflar</option>
                        <option value="11">11.Sınıflar</option>
                        <option value="12">12.Sınıflar</option>
                    </select>
                </label>
            </div>
    
            {/* Filtrelenmiş Kursları Göster */}
            <div className="cebir-courses-container">
                {filteredCourses.length > 0 ? (
                    filteredCourses.map((course, index) => (
                        <CourseCard
                            key={index}
                            title={course.title}
                            date={`${formatDate(course.startDate)} - ${formatDate(course.endDate)}`}
                            time={`${course.startTime} - ${course.endTime}`}
                            total={course.total}
                            students={course.students}
                            info={course.info}
                        />
                    ))
                ) : (
                    <p>Henüz eklenmiş bir kurs bulunmuyor.</p>
                )}
            </div>
        </div>
    );
};

export default Courses;
